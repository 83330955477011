import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../modules/layout"
import Fotos from "../../modules/m10_fotos"
import Servicios from "../../modules/m08_Services"
import M11FotosBefore from "../../modules/m11_antesydespues"
import Casos from "../../modules/m20_casos"
import Hero from "../../modules/m07_heroCaso"
import datos from "../../data/casos.js"
import Gallery from "../../modules/m13_FullsizeModalFotos"
import img1 from "../../images/san-venancio/g1.jpg"
import img2 from "../../images/san-venancio/g2.jpg"
import img3 from "../../images/san-venancio/g3.jpg"
import img4 from "../../images/san-venancio/g4.jpg"
import img5 from "../../images/san-venancio/g5.jpg"
import img6 from "../../images/san-venancio/g6.jpg"
import img7 from "../../images/san-venancio/g7.jpg"
import antes from "../../images/san-venancio/foto_antes.jpg"
import despues from "../../images/san-venancio/foto_despues.jpg"
import Block from "../../components/block"
import Card from "../../components/cardCaso"
import datacasos from "../../data/casos"

const imgList = [img1, img2, img3, img4, img5, img6, img7]
const data = datos.filter(d => d.nombre === "San Venancio")[0]

const CasoPage = () => {
  const [galleryOpen, setGalleryOpen] = useState(false)

  const Imgs = useStaticQuery(
    graphql`
      query {
        header: file(relativePath: { eq: "san-venancio/foto_despues.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mapa: file(relativePath: { eq: "san-venancio/San_Venancio.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        a1: file(relativePath: { eq: "san-venancio/g4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        a2: file(relativePath: { eq: "san-venancio/g3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        b1: file(relativePath: { eq: "san-venancio/g1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 840, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        b2: file(relativePath: { eq: "san-venancio/g2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 840, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  return (
    <Layout
      title={data.seo.title}
      desc={data.seo.title}
      keywords={data.seo.keywords}
      url="casos/san-venancio"
    >
      <Hero
        HeroPhoto={Imgs.header}
        data={data}
        vr360={data.vr360}
        openGallery={() => setGalleryOpen(true)}
        Mapa={Imgs.mapa}
        tagid07360="TM41-SanVenancio-MóduloVista360S-Boton360"
        tagid07fotos="TM42-SanVenancio-MóduloFotosS-BotonFotos"
      />
      <M11FotosBefore
        title="Comparativa"
        subtitle="Así es como se ha transformado el inmueble, la gran diferencia entre el estado incial y la reforma final"
        srcAntes={antes}
        altAntes="Lamentable imagen de local en obras con todo a medio construir"
        srcDespues={despues}
        altDespues="Hermosa vista final del inmueble terminado y decorado con estilo"
        tagidhandler="TM48-SanVenancio-MóduloComparador-ComparadorAntesDespues"
      />

      <Fotos
        Imgs={Imgs}
        vr360={data.vr360}
        d={data}
        openGallery={() => setGalleryOpen(true)}
        tagid10360="TM43-SanVenancio-MóduloVista360I-Boton360"
        tagid10Fotos="TM44-SanVenancio-MóduloFotosI-BotonFotos"
        alt01="Imagen de la cama"
        alt02="Imagen de la cocina"
        alt03="Imagen del salón y ventana"
        alt04="Imagen del recibidor"
      />
      <Servicios />

      <Block>
        <Casos>
          <Card
            datacasos={datacasos[0]}
            tagmanagerid="TM45-SanVenancio-MóduloCasosPracticos-JuanIziar"
            title="De un restaurante a tres viviendas"
          ></Card>
          <Card
            datacasos={datacasos[2]}
            tagmanagerid="TM46-SanVenancio-MóduloCasosPracticos-PuertoReal"
            title="De una clínica dental a dos viviendas"
          ></Card>
          <Card
            datacasos={datacasos[3]}
            tagmanagerid="TM47-SanVenancio-MóduloCasosPracticos-Zuera"
            title="De una pescadería a un estudio"
          ></Card>
        </Casos>
      </Block>
      <Gallery
        galleryOpen={galleryOpen}
        onClose={() => setGalleryOpen(false)}
        imgGallery={imgList}
        address={data.direccion}
      />
    </Layout>
  )
}

export default CasoPage
